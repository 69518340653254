.w-header {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 80px;
}
.w-head {
  font-size: 30px;
  font-weight: 600;
}
.procced-btn {
  margin-bottom: 20px;
  height: 70px;
  font-size: 20px;
  letter-spacing: 5px;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-btn-primary {
  background: #20aedb !important;
}
