.camera-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.camera {
  width: 100%;
  height: 100%;
}

.overlay-text {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  background-color: rgba(53, 224, 73, 0.438);
  color: rgb(255, 255, 255);
}

.overlay-text p {
  color: white;
}

.current-view-text {
  position: absolute;
  width: 95%;
  top: 5%;
  left: 46.2%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 600;
  overflow-x: auto !important;
  overflow-y: auto !important;
  padding: 5px 65px;
}

.overlay-text p {
  margin-bottom: 0px !important;
}

#container-circles {
  position: absolute !important;
  left: 94% !important;
  bottom: 220px !important;
}

#inner-circle,
#outer-circle {
  background: rgba(254, 0, 0, 0.606) !important;
}

#inner-circle {
  background: white !important;
  border-radius: 3px !important;
}

.ant-steps-item-finish .ant-steps-item-title {
  color: rgb(0, 255, 21) !important;
}

.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: rgb(0, 255, 21) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  color: rgb(0, 255, 21) !important;
}
.antd-steps {
  background-color: rgba(150, 148, 148, 0.414) !important;
  padding: 10px 20px 2.5px 20px;
}

.active-step {
  border: 1px solid rgb(0, 255, 21);
}

.ant-steps-item-process {
  background-color: rgba(254, 0, 0, 0.566) !important;
}

.ant-steps-item-title {
  color: white !important;
}
.ant-steps-icon {
  color: white !important;
}

/* here */
/* 
.current-view-text {
  align-items: center;
  justify-content: center;
  width: 75% !important;
  overflow-x: auto; 
  padding: 5px 65px;
}

.current-view-text .ant-steps {
  min-width: 50px; 
  display: flex;
}

.current-view-text .ant-steps-item {
  flex: 1 0 150px; 
}

.current-view-text .ant-steps-horizontal .ant-steps-item {
  white-space: nowrap; 
}

.current-view-text::-webkit-scrollbar {
  height: 8px; 
} */
.current-view-text .ant-steps-item {
  flex: 1 0 150px;
}

.guide {
  position: absolute;
  top: 65%;
  left: 88%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  color: rgb(255, 255, 255);
}
