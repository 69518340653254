/* src/components/ComingSoon.css */

.coming-container {
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

h1 {
    font-size: 36px;
    color: #333;
}

p {
    font-size: 18px;
    color: #666;
}

.countdown {
    font-size: 24px;
    color: #555;
}
