.home-slider {
  overflow: "hidden";
  padding: 1.2rem 0;
}

.home-slider img {
  border: 1px solid rgb(116, 113, 113);
  height: 170px;
  width: 180px;
  padding: 20px;
  border-radius: 10px;
}
.slider-container {
  justify-content: center !important;
  align-items: center !important;
  margin-top: 50px;
}
.slick-prev {
  left: 0px !important;
}

.slick-prev {
  z-index: 999 !important;
  background-color: red !important;
}

.slick-next {
  left: 0px !important;
}

.slick-next {
  z-index: 999 !important;
  background-color: red !important;
}

.custom-tip {
  color: black !important;
  font-weight: 500;
}

.uploaded {
  border: 5px solid green !important;
}

.react-multiple-carousel__arrow {
  margin-top: 15px !important;
}

.home-slider .uploaded-success {
  border: 0.3px solid rgb(116, 113, 113);
  height: 125px;
  width: 125px;
  padding: 5px;
  border-radius: 10px;
}

.custom-dot-list-style button {
  border: none;
  background: rgb(255, 68, 68);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
}
.react-multiple-carousel__arrow--left {
  left: 0;
}
.sbmt-btn {
  width: 300px;
  padding: 8px;
  border: none;
  background: #147614eb;
  color: white;
  font-size: 1000;
  font-weight: 600;
  cursor: pointer;
  height: 50px;
  border-radius: 1px;
}

@media only screen and (min-width: 600px) {
  .home-slider img {
    border: 1px solid rgb(116, 113, 113) !important;
    height: 100px !important;
    width: 100px !important;
    padding: 4.5px !important;
    border-radius: 10px !important;
  }
  .main-heading {
    font-size: 20px;
    font-weight: 700;
  }
  .img-type {
    font-size: 15;
    font-weight: 600;
  }
}

#upload-btn {
  display: none; /* Hide the default file input button */
}

#upload-label {
  cursor: pointer; /* Change cursor to pointer */
}

.upload-btns {
  margin-right: 25px;
  cursor: pointer;
}
